import React from 'react';
import {
  StaticRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { getAppRouterBasePath } from '@az/utility';
import styled from 'styled-components';
import { ErrorView } from '@az/components';

import GlobalStyle from './GlobalStyle';

const FullScreenErrorView = styled(ErrorView)`
  min-height: 100%;
  overflow: auto;
`;

const route = document.querySelector('html').getAttribute('data-static-route') || window.location.pathname;

function App() {
  return (
    <>
      <GlobalStyle />
      <Router location={route} basename={getAppRouterBasePath('/errors')}>
        <Switch>
          <Route exact path="/404">
            <FullScreenErrorView code="404" />
          </Route>
          <Route exact path="/500">
            <FullScreenErrorView code="500" />
          </Route>
          <Route exact path="/503">
            <FullScreenErrorView code="503" />
          </Route>
          <Route>
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
